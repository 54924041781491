<template>
  <div class="row">
    <button
      class="btn btn-primary px-5 right-button"
      v-if="!isNewPreset"
      @click="onSubmit(2)"
    >
      Save
    </button>
    <button
      class="btn btn-primary px-5 right-button"
      v-else
      type="submit"
      @click="onSubmit(1)"
    >
      Create
    </button>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <div v-if="isNewPreset">
        <h3>New Preset</h3>
      </div>
      <div v-else>
        <h3>{{ formState.name }}</h3>
      </div>
    </div>
  </div>
  <a-form
    :model="formState"
    label-align="left"
    layout="vertical"
    @submit="onSubmit"
  >
    <!-- Preset Details -->
    <div class="row">
      <h5 class="col-lg-12 col-md-12 col-sm-12 col-12">Preset Details</h5>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Preset Name" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input v-model:value="formState.name" placeholder="Preset Name" />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2"></div>
    </div>
    <!-- Video Title -->
    <div class="row">
      <h5 class="col-lg-11 col-md-10 col-sm-10 col-10">Video Titles</h5>
      <h6 class="col-lg-1 col-md-2 col-sm-2 col-2">Editable</h6>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item
          label="
        Show Video Title"
        />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-switch
          :default-checked="formState.enableIntro.value"
          v-model:checked="formState.enableIntro.value"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.enableIntro.editable"
          v-model:checked="formState.enableIntro.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Video Title" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.videoTitle.value"
          placeholder="Video Title"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.videoTitle.editable"
          v-model:checked="formState.videoTitle.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Video Subtitle" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.videoSubtitle.value"
          placeholder="Video Subtitle"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.videoSubtitle.editable"
          v-model:checked="formState.videoSubtitle.editable"
        ></a-switch>
      </div>
    </div>

    <!-- Banner -->
    <div class="row">
      <h5 class="col-lg-11 col-md-10 col-sm-10 col-10">Banner</h5>
      <h6 class="col-lg-1 col-md-2 col-sm-2 col-2">Editable</h6>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item
          label="
        Show Banner"
        />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-switch
          :default-checked="formState.enableBanner.value"
          v-model:checked="formState.enableBanner.value"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.enableBanner.editable"
          v-model:checked="formState.enableBanner.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Presenter Name" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.presenterName.value"
          placeholder="Presenter Name"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.presenterName.editable"
          v-model:checked="formState.presenterName.editable"
        ></a-switch>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Presenter Title" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.presenterTitle.value"
          placeholder="Presenter Title"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.presenterTitle.editable"
          v-model:checked="formState.presenterTitle.editable"
        ></a-switch>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item
          label="Rounded Corner"
        />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-slider
          :defaultValue="formState.roundedCorners.value"
          :tip-formatter="formatter"
          v-model:value="formState.roundedCorners.value"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.roundedCorners.editable"
          v-model:checked="formState.roundedCorners.editable"
        ></a-switch>
      </div>
    </div>

    <!-- Call To Action -->
    <div class="row">
      <h5 class="col-lg-11 col-md-10 col-sm-10 col-10">Call To Action</h5>
      <h6 class="col-lg-1 col-md-2 col-sm-2 col-2">Editable</h6>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item
          label="
        Show Call To Action"
        />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-switch
          :default-checked="formState.enableOutro.value"
          v-model:checked="formState.enableOutro.value"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.enableOutro.editable"
          v-model:checked="formState.enableOutro.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Call To Action" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.callToAction.value"
          placeholder="Call To Action"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.callToAction.editable"
          v-model:checked="formState.callToAction.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Website URL" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.websiteURL.value"
          placeholder="www.mywebsite.com"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.websiteURL.editable"
          v-model:checked="formState.websiteURL.editable"
        ></a-switch>
      </div>
    </div>

    <!-- Headlines  -->
    <div class="row">
      <h5 class="col-lg-11 col-md-10 col-sm-10 col-10">Headlines</h5>
      <h6 class="col-lg-1 col-md-2 col-sm-2 col-2">Editable</h6>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item
          label="
        Show Headlines"
        />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-switch
          :default-checked="formState.enableHeadlines.value"
          v-model:checked="formState.enableHeadlines.value"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.enableHeadlines.editable"
          v-model:checked="formState.enableHeadlines.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Headlines" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.headlinesTitle.value"
          placeholder="Headlines"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.headlinesTitle.editable"
          v-model:checked="formState.headlinesTitle.editable"
        />
      </div>
    </div>

    <!-- Disclaimer  -->
    <div class="row">
      <h5 class="col-lg-11 col-md-10 col-sm-10 col-10">Disclaimer</h5>
      <h6 class="col-lg-1 col-md-2 col-sm-2 col-2">Editable</h6>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item
          label="
        Show Disclaimer"
        />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-switch
          :default-checked="formState.enableDisclaimer.value"
          v-model:checked="formState.enableDisclaimer.value"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.enableDisclaimer.editable"
          v-model:checked="formState.enableDisclaimer.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Disclaimer" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.disclaimer.value"
          placeholder="Disclaimer"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.disclaimer.editable"
          v-model:checked="formState.disclaimer.editable"
        />
      </div>
    </div>

    <!-- Logo -->
    <div class="row">
      <h5 class="col-lg-11 col-md-10 col-sm-10 col-10">Logo</h5>
      <h6 class="col-lg-1 col-md-2 col-sm-2 col-2">Editable</h6>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Logo" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-upload
          :show-upload-list="false"
          name="logo"
          list-type="picture-card"
          class="image-uploader"
          :before-upload="beforeUpload"
          :customRequest="customUploadLogo"
          @change="handleChangeLogo"
          :value="formState.logoURL.value"
        >
          <img
            v-if="formState.logoURL.value"
            :src="formState.logoURL.value"
            alt="Logo"
            class="image-card"
          />
          <div v-else>
            <loading-outlined v-if="loadingLogo" />
            <plus-outlined v-else />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.logoURL.editable"
          v-model:checked="formState.logoURL.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Logo Scale" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-slider
          :defaultValue="formState.logoScale.value"
          v-model:value="formState.logoScale.value"
          :tip-formatter="formatter"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.logoScale.editable"
          v-model:checked="formState.logoScale.editable"
        ></a-switch>
      </div>
    </div>

    <!-- Fonts -->
    <div class="row">
      <h5 class="col-lg-11 col-md-10 col-sm-10 col-10">Fonts</h5>
      <h6 class="col-lg-1 col-md-2 col-sm-2 col-2">Editable</h6>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Primary Font" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-select
          v-model:value="formState.primaryFont.value"
          :style="{ 'font-family': formState.primaryFont.value }"
        >
          <a-select-option
            :value="font.value"
            v-for="font in fonts"
            :key="font"
            :style="{ 'font-family': font }"
            >{{ font }}</a-select-option
          >
        </a-select>
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.primaryFont.editable"
          v-model:checked="formState.primaryFont.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Secondary Font" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-select
          v-model:value="formState.secondaryFont.value"
          :style="{ 'font-family': formState.secondaryFont.value }"
        >
          <a-select-option
            :value="font.value"
            v-for="font in fonts"
            :key="font"
            :style="{ 'font-family': font }"
            >{{ font }}</a-select-option
          >
        </a-select>
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.secondaryFont.editable"
          v-model:checked="formState.secondaryFont.editable"
        />
      </div>
    </div>

    <!-- Colours -->
    <div class="row">
      <h5 class="col-lg-11 col-md-10 col-sm-10 col-10">Colours</h5>
      <h6 class="col-lg-1 col-md-2 col-sm-2 col-2">Editable</h6>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Background Colour" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.backgroundColor.value"
          placeholder="#FFFFFF"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.backgroundColor.editable"
          v-model:checked="formState.backgroundColor.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Text Colour" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.textColor.value"
          placeholder="#FFFFFF"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.textColor.editable"
          v-model:checked="formState.textColor.editable"
        />
      </div>
    </div>
    
    <!--Background-->
    <div class="row">
      <h5 class="col-lg-11 col-md-10 col-sm-10 col-10">Backgrounds</h5>
      <h6 class="col-lg-1 col-md-2 col-sm-2 col-2">Editable</h6>
    </div>
    
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Background Image" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-upload
          name="logo"
          :show-upload-list="false"
          list-type="picture-card"
          class="image-uploader"
          :before-upload="beforeUpload"
          :customRequest="customUploadTexture"
          @change="handleChangeTexture"
        >
          <img
            v-if="formState.textureURL.value"
            :src="formState.textureURL.value"
            alt="texture"
            class="image-card"
          />
          <div v-else>
            <loading-outlined v-if="loadingTexture" />
            <plus-outlined v-else />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.textureURL.editable"
          v-model:checked="formState.textureURL.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Blend Strength"/>
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-slider
          :defaultValue="formState.textureStrength.value"
          v-model:value="formState.textureStrength.value"
          :tip-formatter="formatter"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.textureStrength.editable"
          v-model:checked="formState.textureStrength.editable"
        ></a-switch>
      </div>
    </div>

    <!--Watermark-->
    <div class="row">
      <h5 class="col-lg-11 col-md-10 col-sm-10 col-10">Fonts</h5>
      <h6 class="col-lg-1 col-md-2 col-sm-2 col-2">Editable</h6>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item
          label="
        Watermark"
        />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-select v-model:value="formState.watermark.value">
          <a-select-option
            :value="option.value"
            v-for="option in watermarkOptions"
            :key="option.value"
            >{{ option.name }}</a-select-option
          >
        </a-select>
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.watermark.editable"
          v-model:checked="formState.watermark.editable"
        />
      </div>
    </div>

    <!-- Captions settings -->
    <div class="row">
      <h5 class="col-lg-11 col-md-10 col-sm-10 col-10">Captions</h5>
      <h6 class="col-lg-1 col-md-2 col-sm-2 col-2">Editable</h6>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item
          label="
        Floating Captions"
        />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-switch
          :default-checked="formState.floatingCaptions.value"
          v-model:checked="formState.floatingCaptions.value"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.floatingCaptions.editable"
          v-model:checked="formState.floatingCaptions.editable"
        />
      </div>
    </div>
    <div class="row" v-if="formState.floatingCaptions.value">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item
          label="Captions Rounded Corner"
        />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-slider
          :defaultValue="formState.captionsCornerRadius.value"
          :tip-formatter="formatter"
          v-model:value="formState.captionsCornerRadius.value"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.captionsCornerRadius.editable"
          v-model:checked="formState.captionsCornerRadius.editable"
        ></a-switch>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Captions Background Colour" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.captionsBackgroundColor.value"
          placeholder="#FFFFFF"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.captionsBackgroundColor.editable"
          v-model:checked="formState.captionsBackgroundColor.editable"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-3 col-3">
        <a-form-item label="Captions Text Colour" />
      </div>
      <div class="col-lg-9 col-md-8 col-sm-7 col-7">
        <a-input
          v-model:value="formState.captionsTextColor.value"
          placeholder="#FFFFFF"
        />
      </div>
      <div class="col-lg-1 col-md-2 col-sm-2 col-2">
        <a-switch
          :default-checked="formState.captionsTextColor.editable"
          v-model:checked="formState.captionsTextColor.editable"
        />
      </div>
    </div>
  </a-form>
  <div class="row">
    <button
      class="btn btn-primary px-5 right-button"
      v-if="!isNewPreset"
      @click="onSubmit(2)"
    >
      Save
    </button>
    <button
      class="btn btn-primary px-5 right-button"
      v-else
      type="submit"
      @click="onSubmit(1)"
    >
      Create
    </button>
  </div>
</template>

<script>
import {
  InboxOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";
import { defineComponent, reactive, ref, toRaw } from "vue";
import { useStore } from "vuex";
var fonts = require("@/css/fonts/fonts.json");

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default defineComponent({
  components: {
    InboxOutlined,
    LoadingOutlined,
    PlusOutlined,
  },
  emits: ["addNewPreset", "updatePreset", "setAsDefault"],
  props: ["preset", "orgId", "isNewPreset", "customFonts"],
  methods: {
    onSubmit(mode) {
      switch (mode) {
        case 1:
          this.$emit("addNewPreset", toRaw(this.formState));
          break;
        case 2:
          const updateData = {
            newPreset: toRaw(this.formState),
            presetId: this.$props.preset.id,
          };
          this.$emit("updatePreset", updateData);
          break;
        default:
          break;
      }
    },
  },
  setup(props) {
    const formState = reactive({
      logoURL: {
        value: null,
        editable: true,
      },
      logoScale: {
        value: 0.5,
        editable: true,
      },
      textureURL: {
        value: null,
        editable: true,
      },
      roundedCorners: {
        value: 0,
        editable: true,
      },
      textureStrength: {
        value: 0,
        editable: true,
      },
      captionsCornerRadius: {
        value: 0,
        editable: true,
      },
    });
    const isNewPreset = ref(false);
    props.preset.roundedCorners.value = props.preset.roundedCorners.value * 100;
    props.preset.textureStrength.value = props.preset.textureStrength.value * 100;
    props.preset.logoScale.value = props.preset.logoScale.value * 100;
    props.preset.captionsCornerRadius.value = props.preset.captionsCornerRadius.value * 100;
    if (props.customFonts && Array.isArray(props.customFonts)) {
    props.customFonts.forEach((font) => {
      fonts.unshift(font);
    });
    }
    Object.assign(formState, props.preset);
    isNewPreset.value = props.isNewPreset;

    const store = useStore();

    const formatter = (value) => {
      return `${value}%`;
    };

    const watermarkOptions = [
      { name: "Brivvio", value: "brivvio" },
      { name: "Brand", value: "brand" },
      { name: "None", value: "none" },
    ];

    const loadingLogo = ref(false);
    const loadingTexture = ref(false);

    const handleChangeLogo = (info) => {
      if (info.file.status === "uploading") {
        loadingLogo.value = true;
        return;
      }
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, (imageUrl) => {
          imageUrl = imageUrl;
          loadingLogo.value = false;
        });
      }
    };

    const handleChangeTexture = (info) => {
      if (info.file.status === "uploading") {
        loadingTexture.value = true;
        return;
      }
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, (imageUrl) => {
          imageUrl = imageUrl;
          loadingTexture.value = false;
        });
      }
    };

    const beforeUpload = (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        notification.warning({
          message: "Not an image",
          description: "You can only upload JPG or PNG file!",
        });
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        notification.warning({
          message: "Oversized image",
          description: "Image must smaller than 2MB!",
        });
      }
      return isJpgOrPng && isLt2M;
    };

    const customUpload = (onSuccess, onError, file, isLogo) => {
      const metadata = {
        contentType: file.type,
      };
      store
        .dispatch("user/UPLOAD_IMAGE", {
          imageData: file,
          imageName: file.name
            .split(".")
            .slice(0, -1)
            .join("."),
          orgId: props.orgId,
          isLogo: isLogo,
          meta: metadata,
        })
        .then((snap) => {
          if (snap) {
            onSuccess(null, file);
            if (isLogo) {
              formState.logoURL.value = snap;
            } else {
              formState.textureURL.value = snap;
            }
          } else {
            notification.warning({
              message: "Error!",
              description: "Failed to upload image. Please try again!",
            });
            onError(res);
          }
        });
    };

    const customUploadTexture = ({ onSuccess, onError, file }) => {
      customUpload(onSuccess, onError, file, false);
    };

    const customUploadLogo = ({ onSuccess, onError, file }) => {
      customUpload(onSuccess, onError, file, true);
    };

    const isFormComplete = () => {
      return false;
    };

    return {
      formState,
      formatter,
      handleChangeLogo,
      handleChangeTexture,
      beforeUpload,
      customUploadLogo,
      customUploadTexture,
      isFormComplete,
      loadingLogo,
      loadingTexture,
      watermarkOptions,
      isNewPreset,
      fonts,
    };
  },
});
</script>

<style scoped>
.image-card {
  width: 150px;
  height: 150px;
}
.image-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.right-button {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 15px;
}
</style>
